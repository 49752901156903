var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"main pa-5"},[_c('section',{staticClass:"invoice"},[_c('div',{staticClass:"invoice__content",style:(_vm.$vuetify.breakpoint.smAndDown ? 'overflow-x: scroll;' : '')},[_c('table',{attrs:{"id":"customers"}},[_vm._m(0),_c('tr',[_c('td',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.$router.push({ name: 'invoice form' })}}},[_vm._v("№23415")]),_c('td',[_vm._v("2000")]),_c('td',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.$router.push({ name: 'invoice form' })}}},[_vm._v("№23415")]),_c('td',[_vm._v("1000")]),_vm._m(1),_c('td',[_vm._v("1000")]),_vm._m(2),_c('td',[_c('div',{staticClass:"d-flex align-items-center cursor-pointer",on:{"click":function($event){return _vm.$router.push({ name: 'invoice form' })}}},[_c('span',{staticClass:"circle"}),_c('span',{staticClass:"show"},[_vm._v("просмотреть")])])])]),_c('tr',[_c('td',[_vm._v("№23415")]),_c('td',[_vm._v("2000")]),_c('td',[_vm._v("№23415")]),_c('td',[_vm._v(" ")]),_vm._m(3),_c('td',[_vm._v("1000")]),_vm._m(4),_c('td',[_c('div',{staticClass:"d-flex align-items-center cursor-pointer",on:{"click":function($event){return _vm.$router.push({ name: 'invoice form' })}}},[_c('span',{staticClass:"circle"}),_c('span',{staticClass:"show"},[_vm._v("просмотреть")])])])])])]),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("Номер заказа")]),_c('th',[_vm._v("Cумма")]),_c('th',[_vm._v("Инвойс")]),_c('th',[_vm._v("Сумма")]),_c('th',[_vm._v("Статус платежа")]),_c('th',[_vm._v("Сумма")]),_c('th',[_vm._v("Баланс суммы")]),_c('th',[_vm._v(" ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('span',{staticClass:"status-pending"},[_vm._v("в ожидании")]),_vm._v(" выставлен 23.01.22")])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('span',{staticClass:"minus"},[_vm._v("-1000")]),_c('span',{staticClass:"plus"},[_vm._v("+4")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('span',{staticClass:"status-active"},[_vm._v("зачислено")]),_vm._v(" 23.01.22")])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('span',{staticClass:"minus"},[_vm._v("-1000")]),_c('span',{staticClass:"plus"},[_vm._v("+4")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"invoice__total"},[_c('span',[_vm._v("Ваш баланс")]),_c('span',{staticClass:"total"},[_vm._v("+$456")])])
}]

export { render, staticRenderFns }