<template>
  <main class="main pa-5">
    <section class="invoice">
      <div
        class="invoice__content"
        :style="$vuetify.breakpoint.smAndDown ? 'overflow-x: scroll;' : ''"
      >
        <table id="customers">
          <tr>
            <th>Номер заказа</th>
            <th>Cумма</th>
            <th>Инвойс</th>
            <th>Сумма</th>
            <th>Статус платежа</th>
            <th>Сумма</th>
            <th>Баланс суммы</th>
            <th>&nbsp;</th>
          </tr>
          <tr>
            <td
              class="cursor-pointer"
              @click="$router.push({ name: 'invoice form' })"
              >№23415</td
            >
            <td>2000</td>
            <td
              class="cursor-pointer"
              @click="$router.push({ name: 'invoice form' })"
              >№23415</td
            >
            <td>1000</td>
            <td>
              <span class="status-pending">в ожидании</span>
              выставлен 23.01.22</td
            >
            <td>1000</td>
            <td
              ><span class="minus">-1000</span><span class="plus">+4</span></td
            >
            <td>
              <div
                class="d-flex align-items-center cursor-pointer"
                @click="$router.push({ name: 'invoice form' })"
              >
                <span class="circle"> </span>
                <span class="show">просмотреть</span>
              </div>
            </td>
          </tr>
          <tr>
            <td>№23415</td>
            <td>2000</td>
            <td>№23415</td>
            <td>&nbsp;</td>
            <td><span class="status-active">зачислено</span> 23.01.22</td>
            <td>1000</td>
            <td
              ><span class="minus">-1000</span><span class="plus">+4</span></td
            >
            <td>
              <div
                class="d-flex align-items-center cursor-pointer"
                @click="$router.push({ name: 'invoice form' })"
              >
                <span class="circle"> </span>
                <span class="show">просмотреть</span>
              </div>
            </td>
          </tr>
        </table>
      </div>
      <div class="invoice__total">
        <span>Ваш баланс</span>
        <span class="total">+$456</span>
      </div>
    </section>
  </main>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans:ital,wght@0,100;0,300;0,400;0,500;0,700;0,800;1,300;1,400;1,500;1,700;1,800&family=Roboto+Condensed:ital,wght@0,300;0,400;500;0,700;1,300&display=swap');
.invoice {
  &__content {
    background-color: #ffffff;
    border-radius: 1px;
    padding: 27px 27px 0;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  &__total {
    padding: 27px 0;
    width: 276px;
    background-color: #ffffff;
    border-radius: 1px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    float: right;
    margin-top: 6px;

    span {
      font-family: 'Alegreya Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #666666;
    }

    .total {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      text-align: center;
      color: #525350;
    }
  }
}

#customers {
  width: 100%;
}

#customers td {
  padding: 8px;
  font-family: 'Alegreya Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  white-space: nowrap;
}

#customers th {
  padding: 10px;
  text-align: start;
  font-family: 'Alegreya Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  white-space: nowrap;
}

#customers .plus,
#customers .minus {
  display: block;
}

#customers .repeat,
#customers .show {
  color: #423f3d;
}

#customers .circle {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: #cfebcc;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 9px;
}

#customers tr:not(:first-child):hover {
  background-color: #fbfbfb;
}
#customers tr:not(:first-child):not(:last-child) {
  height: 66px;
  border-bottom: 1px solid #f5f5f5;
}

#customers tr:first-child {
  height: 40px;
  background-color: #fbfbfb;
}

#customers tr:first-child th,
#customers tr td:first-child {
  color: #423f3d;
}
#customers tr td {
  color: #666666;
}

.status-active {
  color: #9cc198;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.status-active::before {
  content: '';
  width: 5px;
  height: 5px;
  border-radius: 100%;
  background-color: #9cc198;
  display: inline-block;
  margin-right: 6px;
}

.status-pending {
  color: #ecad62;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.status-pending::before {
  content: '';
  width: 5px;
  height: 5px;
  border-radius: 100%;
  background-color: #ecad62;
  display: inline-block;
  margin-right: 6px;
}

table {
  border-spacing: 0px;
}
</style>
